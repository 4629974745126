import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';

import illustrations from '@common/illustrations';

import Sections from '@components/Sections';
import TextSliderSection from '@components/TextSliderSection';

const NetworkIllustration = illustrations.network;

const HaveSpacePage = ({
  data: {
    contentfulHaveSpacePage: { title, subtitle, sections, textSliderTitle, textSlider },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Denizen currently serves the districts of Mitte and Kreuzberg. If you have spare space that could be put to better use, we will help you fill those empty seats so you can cut your operating costs, meet new people and reduce wasted energy."
      pageImage="/seo/havespace.jpg"
    />
    <main>
      <MobileIllustrationContainer>
        <NetworkIllustration mobile />
      </MobileIllustrationContainer>
      <section>
        <h1>{title}</h1>
        <Subtitle text={subtitle} />
      </section>
      <TextSliderSection
        imageRight
        title={textSliderTitle}
        items={textSlider}
        illustration={NetworkIllustration}
        context="havespace"
      />
      <Sections sections={sections} />
    </main>
  </>
);

// TODO
// since it's repeating (like the main title), perhaps move out
const Subtitle = styled(RichText)`
  grid-column: 1 / -1;
  text-align: center;

  ${mqdown.small} {
    margin-top: var(--space-xxs);
  }

  ${mq.small} {
    font: var(--font-subtitle);
  }

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const MobileIllustrationContainer = styled.section`
  &:first-child {
    padding-top: var(--space-s);
    padding-bottom: 0;
    margin-bottom: calc(var(--space-xxs) * -4);
  }

  > div {
    grid-column: 1 / -1;
  }

  ${mq.small} {
    display: none;
  }
`;

export const haveSpacePageQuery = graphql`
  query HaveSpacePageQuery($locale: String) {
    contentfulHaveSpacePage(node_locale: { eq: $locale }) {
      node_locale
      title
      subtitle {
        raw
      }
      textSliderTitle
      textSlider {
        id
        text
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(HaveSpacePage);
